define('slate-payroll/config/app-data', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var states = [{
    v: 'AL',
    l: 'Alabama'
  }, {
    v: 'AK',
    l: 'Alaska'
  }, {
    v: 'AZ',
    l: 'Arizona'
  }, {
    v: 'AR',
    l: 'Arkansas'
  }, {
    v: 'CA',
    l: 'California'
  }, {
    v: 'CO',
    l: 'Colorado'
  }, {
    v: 'CT',
    l: 'Connecticut'
  }, {
    v: 'DE',
    l: 'Delaware'
  }, {
    v: 'DC',
    l: 'District of Columbia'
  }, {
    v: 'FL',
    l: 'Florida'
  }, {
    v: 'GA',
    l: 'Georgia'
  }, {
    v: 'HI',
    l: 'Hawaii'
  }, {
    v: 'ID',
    l: 'Idaho'
  }, {
    v: 'IL',
    l: 'Illinois'
  }, {
    v: 'IN',
    l: 'Indiana'
  }, {
    v: 'IA',
    l: 'Iowa'
  }, {
    v: 'KS',
    l: 'Kansas'
  }, {
    v: 'KY',
    l: 'Kentucky'
  }, {
    v: 'LA',
    l: 'Louisiana'
  }, {
    v: 'ME',
    l: 'Maine'
  }, {
    v: 'MD',
    l: 'Maryland'
  }, {
    v: 'MA',
    l: 'Massachusetts'
  }, {
    v: 'MI',
    l: 'Michigan'
  }, {
    v: 'MN',
    l: 'Minnesota'
  }, {
    v: 'MS',
    l: 'Mississippi'
  }, {
    v: 'MO',
    l: 'Missouri'
  }, {
    v: 'MT',
    l: 'Montana'
  }, {
    v: 'NE',
    l: 'Nebraska'
  }, {
    v: 'NV',
    l: 'Nevada'
  }, {
    v: 'NH',
    l: 'New Hampshire'
  }, {
    v: 'NJ',
    l: 'New Jersey'
  }, {
    v: 'NM',
    l: 'New Mexico'
  }, {
    v: 'NY',
    l: 'New York'
  }, {
    v: 'NC',
    l: 'North Carolina'
  }, {
    v: 'ND',
    l: 'North Dakota'
  }, {
    v: 'OH',
    l: 'Ohio'
  }, {
    v: 'OK',
    l: 'Oklahoma'
  }, {
    v: 'OR',
    l: 'Oregon'
  }, {
    v: 'PA',
    l: 'Pennsylvania'
  }, {
    v: 'RI',
    l: 'Rhode Island'
  }, {
    v: 'SC',
    l: 'South Carolina'
  }, {
    v: 'SD',
    l: 'South Dakota'
  }, {
    v: 'TN',
    l: 'Tennessee'
  }, {
    v: 'TX',
    l: 'Texas'
  }, {
    v: 'UT',
    l: 'Utah'
  }, {
    v: 'VT',
    l: 'Vermont'
  }, {
    v: 'VA',
    l: 'Virginia'
  }, {
    v: 'WA',
    l: 'Washington'
  }, {
    v: 'WV',
    l: 'West Virginia'
  }, {
    v: 'WI',
    l: 'Wisconsin'
  }, {
    v: 'WY',
    l: 'Wyoming'
  }];

  var suffixes = ['Sr.', 'Jr.', 'II', 'III', 'IV', 'V'];

  var genders = ['Male', 'Female'];

  var maritalStatuses = ['Single', 'Married'];

  var payPeriodFrequencies = ['Weekly', 'Bi-Weekly', 'Semi-Monthly', 'Monthly'];

  var filingStatuses = ['Exempt'].concat(maritalStatuses, ['Head of Household']);

  var bankAccountTypes = ['Checking', 'Savings'];

  // *  For holidays falling on Saturday
  // ** For holidays falling on Sunday
  var holidays = [
  // 2019
  new Date(2019, 0, 1), // New Year's Day
  new Date(2019, 0, 21), // Martin Luther King, Jr Day
  new Date(2019, 1, 18), // President's Day
  new Date(2019, 4, 27), // Memorial Day
  new Date(2019, 6, 4), // Independence Day
  new Date(2019, 8, 2), // Labor day
  new Date(2019, 9, 14), // Columbus day
  new Date(2019, 10, 11), // Veterans day
  new Date(2019, 10, 28), // Thanksgiving day
  new Date(2019, 11, 25), // Christmas day
  // 2020
  new Date(2020, 0, 1), // New Year's Day
  new Date(2020, 0, 20), // Martin Luther King, Jr Day
  new Date(2020, 1, 17), // President's Day
  new Date(2020, 4, 25), // Memorial Day
  new Date(2020, 6, 4), // Independence Day
  new Date(2020, 8, 7), // Labor day
  new Date(2020, 9, 12), // Columbus day
  new Date(2020, 10, 11), // Veterans day
  new Date(2020, 10, 26), // Thanksgiving day
  new Date(2020, 11, 25), // Christmas day
  // 2021
  new Date(2021, 0, 1), // New Year's Day
  new Date(2021, 0, 18), // Martin Luther King, Jr Day
  new Date(2021, 1, 15), // President's Day
  new Date(2021, 4, 31), // Memorial Day
  new Date(2021, 6, 5), // Independence Day
  new Date(2021, 8, 6), // Labor day
  new Date(2021, 9, 11), // Columbus day
  new Date(2021, 10, 11), // Veterans day
  new Date(2021, 10, 25), // Thanksgiving day
  new Date(2021, 11, 25), // Christmas day
  // 2022
  new Date(2022, 0, 1), // New Year's Day
  new Date(2022, 0, 17), // Martin Luther King, Jr Day
  new Date(2022, 1, 21), // President's Day
  new Date(2022, 4, 30), // Memorial Day
  new Date(2022, 5, 20), // ** Juneteenth
  new Date(2022, 6, 4), // Independence Day
  new Date(2022, 8, 5), // Labor day
  new Date(2022, 9, 10), // Columbus day
  new Date(2022, 10, 11), // Veterans day
  new Date(2022, 10, 24), // Thanksgiving day
  new Date(2022, 11, 26), // ** Christmas day
  // 2023
  new Date(2023, 0, 2), // ** New Year's Day
  new Date(2023, 0, 16), // Martin Luther King, Jr Day
  new Date(2023, 1, 20), // President's Day
  new Date(2023, 4, 29), // Memorial Day
  new Date(2023, 5, 19), // Juneteenth
  new Date(2023, 6, 4), // Independence Day
  new Date(2023, 8, 4), // Labor day
  new Date(2023, 9, 9), // Columbus day
  new Date(2023, 10, 11), // * Veterans day
  new Date(2023, 10, 23), // Thanksgiving day
  new Date(2023, 11, 25), // Christmas day
  // 2024
  new Date(2024, 0, 1), // New Year's Day
  new Date(2024, 0, 15), // Martin Luther King, Jr Day
  new Date(2024, 1, 19), // President's Day
  new Date(2024, 4, 27), // Memorial Day
  new Date(2024, 5, 19), // Juneteenth
  new Date(2024, 6, 4), // Independence Day
  new Date(2024, 8, 2), // Labor day
  new Date(2024, 9, 14), // Columbus day
  new Date(2024, 10, 11), // Veterans day
  new Date(2024, 10, 28), // Thanksgiving day
  new Date(2024, 11, 25), // Christmas day
  // 2025
  new Date(2025, 0, 1), // New Year's Day
  new Date(2025, 0, 20), // Martin Luther King Jr. Day
  new Date(2025, 1, 17), // Washington's Birthday (Presidents Day)
  new Date(2025, 4, 26), // Memorial Day
  new Date(2025, 5, 19), // Junteenth
  new Date(2025, 6, 4), // Independence Day
  new Date(2025, 8, 1), // Labour Day
  new Date(2025, 9, 13), // Christopher Columbus Day
  new Date(2025, 10, 11), // Veterens Day
  new Date(2025, 10, 27), // Thanksgiving Day
  new Date(2025, 11, 25), // Christmas Day
  // 2026
  new Date(2026, 0, 1), // New Year's Day
  new Date(2026, 0, 19), // Martin Luther King Jr. Day
  new Date(2026, 1, 16), // Washington's Birthday (Presidents Day)
  new Date(2026, 4, 25), // Memorial Day
  new Date(2026, 5, 19), // Junteenth
  new Date(2026, 6, 4), // * Independence Day
  new Date(2026, 8, 7), // Labour Day
  new Date(2026, 9, 12), // Christopher Columbus Day
  new Date(2026, 10, 11), // Veterens Day
  new Date(2026, 10, 26), // Thanksgiving Day
  new Date(2026, 11, 25), // Christmas Day
  // 2027
  new Date(2027, 0, 1), // New Year's Day
  new Date(2027, 0, 18), // Martin Luther King Jr. Day
  new Date(2027, 1, 15), // Washington's Birthday (Presidents Day)
  new Date(2027, 4, 31), // Memorial Day
  new Date(2027, 6, 5), // ** Independence Day
  new Date(2027, 8, 6), // Labor Day
  new Date(2027, 9, 11), // Christopher Columbus Day
  new Date(2027, 10, 11), // Veterans Day
  new Date(2027, 10, 25), // Thanksgiving Day
  // 2028
  new Date(2028, 0, 17), // Martin Luther King Jr. Day
  new Date(2028, 1, 21), // Washington's Birthday (Presidents Day)
  new Date(2028, 4, 29), // Memorial Day
  new Date(2028, 5, 19), // Junteenth
  new Date(2028, 6, 4), // Independence Day
  new Date(2028, 8, 4), // Labor Day
  new Date(2028, 9, 9), // Christopher Columbus Day
  new Date(2028, 10, 23), // Thanksgiving Day
  new Date(2028, 11, 25), // Christmas Day
  // 2029
  new Date(2029, 0, 1), // New Year's Day
  new Date(2029, 0, 15), // Martin Luther King Jr. Day
  new Date(2029, 1, 19), // Washington's Birthday (Presidents Day)
  new Date(2029, 4, 28), // Memorial Day
  new Date(2029, 5, 19), // Junteenth
  new Date(2029, 6, 4), // Independence Day
  new Date(2029, 8, 3), // Labor Day
  new Date(2029, 9, 8), // Christopher Columbus Day
  new Date(2029, 10, 12), // ** Veterans Day
  new Date(2029, 10, 22), // Thanksgiving Day
  new Date(2029, 11, 25), // Christmas Day
  //2030
  new Date(2030, 0, 1), // New Year's Day
  new Date(2030, 0, 21), // Martin Luther King Jr. Day
  new Date(2030, 1, 18), // Washington's Birthday (Presidents Day)
  new Date(2030, 4, 27), // Memorial Day
  new Date(2030, 5, 19), // Junteenth
  new Date(2030, 6, 4), // Independence Day
  new Date(2030, 8, 2), // Labor Day
  new Date(2030, 9, 14), // Christopher Columbus Day
  new Date(2030, 10, 11), // ** Veterans Day
  new Date(2030, 10, 28), // Thanksgiving Day
  new Date(2030, 11, 25) // Christmas Day
  ];

  var liabilityMap = function liabilityMap(val) {
    return val > 0 ? 'addEarnings' : 'deduction';
  };

  var paycodeUnitMap = {
    Hours: 'hours',
    Days: 'days',
    Dollars: {
      E: 'addEarnings',
      D: 'deduction',
      L: liabilityMap
    },
    Percent: {
      E: 'addEarnings',
      D: 'deduction',
      L: liabilityMap
    }
  };

  var brandColors = {
    '@brand-default': '#37414',
    '@brand-primary': '#3E606F',
    '@brand-success': '#79BD8F',
    '@brand-warning': '#FFD96A',
    '@brand-danger': '#FF6138',
    '@brand-bright-blue': '#0089EC'
  };

  var statesIncomeTaxExempt = ['AK', 'FL', 'NV', 'NH', 'SD', 'TN', 'TX', 'WA', 'WY'];

  exports.states = states;
  exports.suffixes = suffixes;
  exports.genders = genders;
  exports.maritalStatuses = maritalStatuses;
  exports.payPeriodFrequencies = payPeriodFrequencies;
  exports.holidays = holidays;
  exports.filingStatuses = filingStatuses;
  exports.bankAccountTypes = bankAccountTypes;
  exports.paycodeUnitMap = paycodeUnitMap;
  exports.brandColors = brandColors;
  exports.statesIncomeTaxExempt = statesIncomeTaxExempt;
});